<template lang="">
  <div>
    <v-card :elevation="0" class="v-sheet theme--light br-0">
      <div class="row mt-0 mb-0">
        <div
          class="col-12 col-md-12 m-0 p-0"
          style="margin-top: -5px !important;"
        >
          <DivisorColor :ptl="`3px`" :ptr="`3px`" :pbl="`3px`" :pbr="`3px`" />
        </div>
      </div>

      <v-card-title>
        <v-row class="col-md-12">
          <v-col cols="12" sm="12" md="8">
            Asignación de especialistas - Actores Locales
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <!--   <v-btn
                            color="light-blue-502"
                            dark
                            class="white--text mb-2 float-right"
                            @click="modalNuevo"
                        >
                            Asignar especialista
                        </v-btn> -->
          </v-col>
        </v-row>
        <v-row class="col-md-12 m-0 p-0">
          <v-divider></v-divider>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row class="mt-4">
          <!--inicio:: tabla datos financieros -->
          <v-col cols="12" md="12" sm="12" class="pt-2">
            <v-data-table
              outlined
              dense
              class="elevation-1"
              :headers="headersAsignaciones"
              :items="asignaciones"
              :loading="tableAsignacionesLoading"
              :header-props="{
                sortByText: 'Ordenar por',
              }"
              no-data-text="No se encontraron registros"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-page-first',
                lastIcon: 'mdi-page-last',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right',
                'items-per-page-text': 'Registros por página',
                pageText: '{0}-{1} de {2}',
              }"
              loading-text="Cargando..."
            >
              <template v-slot:top>
                <!-- v-container, v-col and v-row are just for decoration purposes. -->
                <v-container fluid>
                  <v-row>
                    <v-col cols="6" md="6" sm="6" xs="6">
                      <v-text-field
                        autocomplete="off"
                        class="required"
                        dense
                        filled
                        color="blue-grey lighten-2"
                        v-model="filtro"
                        label="Usuario, nombre o correo del especialista"
                        maxlength="200"
                      >
                        <v-icon slot="append" color="blue-grey darken-2"
                          >mdi-magnify</v-icon
                        >
                      </v-text-field>
                    </v-col>

                    <v-col cols="6" md="6" sm="6" xs="6">
                      <v-select
                        v-model="estadoProyecto"
                        :items="estadosProyecto"
                        :loading="false"
                        dense
                        filled
                        label="Estado"
                        :no-data-text="'No existen estados registrados'"
                        menu-props="auto"
                        return-object
                      ></v-select>
                    </v-col>

                    <v-col cols="2" md="2" sm="12" xs="6" class="d-none">
                      <v-btn
                        class="ma-0 white--text d-none"
                        medium
                        color="light-blue darken-2"
                      >
                        <v-icon left>mdi-magnify</v-icon> Buscar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </template>

              <template v-slot:item="{ item }">
                <tr>
                  <!-- <td>{{ item.id }}</td> -->
                  <td>{{ item.usuario }}</td>
                  <td>{{ item.nombreUsuario }}</td>
                  <td>{{ item.email }}</td>
                  <td>
                    {{ item.proyectos.length }}
                    <!-- <v-btn
                                            class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                            small
                                            depressed
                                            :disabled="false"
                                            color="blue-grey lighten-5"
                                            @click="mostrarProyectosUsuario(item.usuario, item.proyectos)"
                                            >
                                            <v-icon left>mdi-eye</v-icon> Ver proyectos
                                        </v-btn> 
                                        -->
                  </td>
                  <td>
                    <v-btn
                      class="ma-2 btn-bg-light white--text mb-2 float-right lighten-2--text font-weight-medium text-capitalize"
                      small
                      color="light-blue-502"
                      dark
                      depressed
                      :disabled="false"
                      @click="
                        obtenerDatosItem(
                          item.usuario,
                          item.nombreUsuario,
                          item.email,
                          1
                        )
                      "
                    >
                      <v-icon left>mdi-plus</v-icon> Asignar Proyectos
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- inicio:: dialog para ver los proyectos asignados-->
    <v-dialog
      v-model="dialogProyectos"
      max-width="1000px"
      persistent
      transition="scroll-y-transition"
    >
      <v-card>
        <v-card-title>
          Proyectos Asignados {{ nickUsuario }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="false"
            @click="dialogProyectos = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="p-10 pt-1">
          <v-row>
            <v-col cols="12" md="12">
              <v-list-item
                two-line
                v-for="(proyecto, i) in proyectosAsignadosUsuario"
                :key="i"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ proyecto.codigo }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    proyecto.nombreProyecto
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- fin:: dialgo para ver los proyectos asignados-->

    <!-- inicio:: dialog asignación especialista-->
    <v-dialog
      v-model="dialogAsignacion"
      max-width="1000px"
      persistent
      transition="scroll-y-transition"
    >
      <v-card>
        <v-card-title>
          Asignación especialista
          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="
              dialogAsignacion = false;
              resetForm();
            "
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="p-10 pt-1">
          <v-form
            ref="form"
            v-on:submit.prevent="registrarAsignacion"
            v-model="validForm"
          >
            <v-row class="mt-4">
              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <v-subheader>
                  <p class="black--text text-h6 pb-0">Datos del especialista</p>
                </v-subheader>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" sm="12">
                <v-list-item two-line style="min-height: 40px !important">
                  <v-list-item-content class="pt-0 pb-0">
                    <v-list-item-title class="black--text text-subtitle-1"
                      >Usuario</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      nickUsuario
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="6" sm="12">
                <v-list-item two-line style="min-height: 40px !important">
                  <v-list-item-content class="pt-0 pb-0">
                    <v-list-item-title class="black--text text-subtitle-1"
                      >Nombre</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      nombreUsuario
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>

              <v-col cols="12" md="6" sm="12">
                <v-list-item two-line style="min-height: 40px !important">
                  <v-list-item-content class="pt-0 pb-0">
                    <v-list-item-title class="black--text text-subtitle-1"
                      >Correo</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      correoUsuario
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>

            <v-row class="mt-4">
              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <v-subheader>
                  <p class="black--text text-h6 pb-0">Seleccionar Proyecto</p>
                </v-subheader>
              </v-col>
            </v-row>

            <!-- <v-row class="mt-6">
                            <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                                <v-autocomplete
                                v-model="datosAsignacion.usuario"
                                :items="usuarios"
                                class="required"
                                dense
                                filled
                                :loading="ddUsuariosLoading"
                                label="Usuario"
                                :item-text="item => `${item.nombre} - ${item.usuario} - ${item.email}`"
                                item-value="usuario"
                                :rules="[selectRequired('usuario')]"
                                :disabled="false"
                                ></v-autocomplete>
                            </v-col>
                        </v-row> -->

           <!--  <v-row>
              <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                <v-autocomplete
                  v-model="entidadSeleccionada"
                  :items="instituciones"
                  class="required"
                  dense
                  filled
                  :loading="ddInstitucionesLoading"
                  label="Institución"
                  :item-text="
                    (item) =>
                      item.sigla
                        ? `${item.nombreInstitucion} (${item.sigla})`
                        : item.nombreInstitucion
                  "
                  item-value="id"
                  :rules="[selectRequired('institución')]"
                  :disabled="false"
                  @change="
                    obtenerProyectosSicooperaEntidadUnidadEjecutora(
                      entidadSeleccionada.id,
                      0
                    )
                  "
                  return-object
                ></v-autocomplete>
              </v-col>
            </v-row> -->

            <!--  <v-row>
                            <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                                <v-autocomplete
                                v-model="proyectoSeleccionado"
                                :items="proyectos"
                                class="required"
                                dense
                                filled
                                :loading="proyectosLoading"
                                label="Seleccionar proyecto CNS"
                                :item-text="item => item.codigo + ` | ` + item.nombreProyecto"
                                item-value="id"
                                :rules="[selectRequired('proyecto')]"
                                :disabled="false"
                               
                                return-object
                                ></v-autocomplete>
                            </v-col>
                        </v-row> -->

            <!-- <v-row>
                            <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                                <v-autocomplete
                                    v-model="unidadEjecutoraSeleccionada"
                                    :items="unidadesEjecutoras"
                                    :clearable="true"
                                    class=""
                                    dense
                                    filled
                                    :loading="unidadesEjecutorasLoading"
                                    label="Unidad ejecutora"
                                    item-text="unidadEjecutora"
                                    item-value="id"
                                    :disabled="false"
                                    @change="obtenerProyectosSicooperaEntidadUnidadEjecutora(entidadSeleccionada.id, unidadEjecutoraSeleccionada.id)"
                                    return-object
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
 -->

            <v-row>
              <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                <v-autocomplete
                  v-model="proyectosSeleccionados"
                  :items="proyectos"
                  class="required"
                  :multiple="true"
                  chips
                  dense
                  filled
                  :loading="proyectosLoading"
                  label="Proyecto"
                  :item-text="item => item.codigo + ` | ` + item.nombreProyecto"
                  item-value="id"
                  :rules="[selectRequired('proyecto')]"
                  :disabled="false"
                  return-object
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="toggle">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            proyectosSeleccionados.length > 0
                              ? 'indigo darken-4'
                              : ''
                          "
                        >
                          {{ icon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          Seleccionar todos
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="12" sm="12" class="pt-0 pb-0">
                <v-spacer></v-spacer>

                <!--:disabled="!validDocForm" type="submit"-->
                <v-btn
                  color="light-blue-502"
                  class="white--text mb-2 float-right"
                  type="submit"
                  :loading="btnRegistroLoading"
                  :elevation="0"
                  :disabled="!validForm || proyectosSeleccionados.length <= 0"
                >
                  Asignar
                </v-btn>
              </v-col>
            </v-row>

            <v-row class="mt-6">
              <v-col cols="12" md="12">
                <v-divider />
              </v-col>
            </v-row>

            <v-row class="mt-4">
              <v-col cols="12" md="12">
                <v-subheader>
                  <p class="black--text text-h6 pb-0">
                    Proyectos asignados al usuario
                  </p>
                </v-subheader>
              </v-col>
            </v-row>

            <v-row class="mt-4">
              <!--inicio:: tabla datos financieros -->
              <v-col cols="12" md="12" sm="12" class="pt-2">
                <v-data-table
                  outlined
                  dense
                  class="elevation-1"
                  :headers="headersProyectosSeleccionados"
                  :items="proyectosAsignadosUsuario"
                  :loading="tableProyectosSeleccionadosoading"
                  :header-props="{
                    sortByText: 'Ordenar por'
                  }"
                  no-data-text="No se encontraron registros"
                  loading-text="Cargando..."
                  :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-page-first',
                    lastIcon: 'mdi-page-last',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right',
                    'items-per-page-text': 'Registros por página',
                    pageText: '{0}-{1} de {2}'
                  }"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <!-- <td>{{ item.id }}</td> -->
                      <td>{{ item.codigo }}</td>
                      <td>{{ item.nombreProyecto }}</td>
                      <!--<td>{{ item.nombreInstitucion }}</td>-->
                      <td>
                        <v-btn
                          class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                          small
                          depressed
                          :disabled="btnEliminarProyectoLoading"
                          color="blue-grey lighten-5"
                          @click="obtenerDatosItem(item.usuario)"
                        >
                          <v-icon left>mdi-delete</v-icon> Eliminar
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>

          <v-row>
            <v-col cols="12" md="12">
              <v-spacer></v-spacer>
              <v-btn
                color="grey lighten-5"
                elevation="0"
                class="mb-2 float-right grey lighten-5 mr-1"
                :loading="false"
                :disabled="false"
                @click="
                  dialogAsignacion = false;
                  resetForm();
                "
              >
                <v-icon>mdi-close</v-icon>
                Cerrar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- fin:: dialog asignación especialista-->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
      transition="scroll-y-transition"
    ></DialogLoader>
    <!---->
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import DialogLoader from "@/view/content/DialogLoader";
import DivisorColor from "@/view/content/biblioteca-componentes/DivisorColor.vue";

import validations from "@/core/untils/validations.js";

import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";
import { OBTENER_UNIDADES_EJECUTORAS_ENTIDAD } from "@/core/services/store/unidadesejecutoras/unidadejecutora.module";
import { OBTENER_PROYECTOS_ACL_SICOOPERA_INSTITUCION_ENTIDAD } from "@/core/services/store/proyectosactoreslocales/proyectoactoreslocales.module";
import { OBTENER_USUARIOS_POR_ROL } from "@/core/services/store/usuarios/usuario.module";
import {
  OBTENER_ASIGNACIONES_ADMINISTRADOR_ACL,
  REGISTRAR_ASIGNACION_SEGUIMIENTO_ESPECIALISTA_ACL,
  OBTENER_ASIGNACION_ESPECIALISTA_ACL
} from "@/core/services/store/proyectosactoreslocales/seguimiento/asignacionseguimientoacl.module";

export default {
  name: "AsignacionSeguimientoActoresLocales",
  data() {
    return {
      proyectoSeleccionado: {},
      filtro: "",
      dialogLoaderVisible: false,
      dialogLoaderText: "",
      asignaciones: [],
      tableAsignacionesLoading: false,
      estadoProyecto: "",
      estadosProyecto: [
        { value: 1, text: "Activo" },
        { value: 2, text: "Inactivo" },
      ],
      usuarios: [],
      usuarioSeleccionado: {},
      ddUsuariosLoading: false,
      unidadEjecutoraSeleccionada: {},
      unidadesEjecutoras: [],
      unidadesEjecutorasLoading: false,
      instituciones: [],
      entidadSeleccionada: {},
      ddInstitucionesLoading: false,
      proyectosLoading: false,
      proyectos: [],
      proyectosSeleccionados: [],
      dialogAsignacion: false,
      btnRegistroLoading: false,
      validForm: false,
      accion: 1,
      nickUsuario: "",
      nombreUsuario: "",
      correoUsuario: "",
      proyectosAsignadosUsuario: [],
      datosAsignacion: {
        usuario: "",
        proyectos: [],
      },

      btnEliminarProyectoLoading: false,
      tableProyectosSeleccionadosoading: false,
      dialogProyectos: false,
      ...validations,
    };
  },

  components: {
    SnackAlert,
    DialogLoader,
    DivisorColor,
  },

  methods: {
    resetForm() {
      this.proyectosSeleccionados = [];
      this.entidadSeleccionada = {};
      //this.proyectos = [];
      this.accion = 1;
    },

    async obtenerAsignaciones() {
      this.tableAsignacionesLoading = true;
      this.asignaciones = [];
      await this.$store
        .dispatch(OBTENER_ASIGNACIONES_ADMINISTRADOR_ACL, { estadoId: 0 })
        .then(res => {
          if (res.status === 200) {
            this.asignaciones = res.data;
          }
          this.tableAsignacionesLoading = false;
        })
        .catch(() => {
          this.tableAsignacionesLoading = false;
          this.asignaciones = [];
        });
    },

    //Obtener los usuarios especialistas (SEGUIMIENTOCNS)
    async obtenerEspecialistas() {
      this.usuarios = [];
      this.ddUsuariosLoading = true;
      await this.$store
        .dispatch(OBTENER_USUARIOS_POR_ROL, {
          estadoId: 1,
          rolUsuario: "SEGUIMIENTOACTLOCALES",
        })
        .then(res => {
          if (res.status === 200) {
            this.usuarios = res.data;
          }
          this.ddUsuariosLoading = false;
        })
        .catch(() => {
          this.usuarios = [];
          this.ddUsuariosLoading = false;
        });
    },

    //Obtener entidades o instituciones
    async obtenerEntidades() {
      this.instituciones = [];
      this.ddInstitucionesLoading = true;
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: `Entidades/all/1` })
        .then(res => {
          if (res.status === 200) {
            this.instituciones = res.data;
          }
          this.ddInstitucionesLoading = false;
        })
        .catch(() => {
          this.instituciones = [];
          this.ddInstitucionesLoading = false;
        });
    },

    //Obtener las unidades ejecutoras
    async obtenerUnidadesEjecutorasPorEntidad(entidadId) {
      this.unidadesEjecutorasLoading = true;
      this.unidadesEjecutoras = [];

      await this.$store
        .dispatch(OBTENER_UNIDADES_EJECUTORAS_ENTIDAD, entidadId)
        .then(res => {
          if (res.status === 200) {
            this.unidadesEjecutoras = this.$store.state.unidadejecutora.unidadesEjecutoras;
          }

          this.unidadesEjecutorasLoading = false;
        })
        .catch(() => {
          this.unidadesEjecutorasLoading = false;
          this.unidadesEjecutoras = [];
        });
    },

    //Obtener las unidades ejecutoras
    async obtenerProyectosSicooperaEntidadUnidadEjecutora(
      entidadId,
      unidadEjecutoraId
    ) {
      this.proyectosLoading = true;
      this.proyectos = [];
      await this.$store
        .dispatch(OBTENER_PROYECTOS_ACL_SICOOPERA_INSTITUCION_ENTIDAD, {
          entidadId: entidadId,
          unidadEjecutoraId: unidadEjecutoraId,
          estadoId: 1,
          id: entidadId,
        })
        .then(res => {
          if (res.status === 200) {
            this.proyectos = res.data;
          }
          this.proyectosLoading = false;
        })
        .catch(() => {
          this.proyectosLoading = false;
          this.proyectos = [];
        });
    },

    toggle() {
      this.$nextTick(() => {
        if (this.likesAllRoles) {
          this.proyectosSeleccionados = [];
        } else {
          this.proyectosSeleccionados = this.proyectos.slice();
        }
      });
    },

    modalNuevo() {
      this.dialogAsignacion = true;
    },

    //Registrar la asignación del especialista al (los) proyecto(s)
    async registrarAsignacion() {
      if (!this.entidadSeleccionada) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `Es necesario seleccionar una institución.`
        );
        return;
      }

      if (this.proyectosSeleccionados.length <= 0) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `Es necesario seleccionar uno o varios proyectos para la asignación.`
        );
        return;
      }

      this.btnRegistroLoading = true;

      let proyectosUsuario = [];

      proyectosUsuario = this.proyectosSeleccionados.map(function(row) {
        return {
          proyectoId: row.id,
          tipoProyecto: row.tipoProyecto,
          nombreProyecto: row.nombreProyecto,
        };
      });

      this.datosAsignacion.proyectos = proyectosUsuario;

      console.log(this.datosAsignacion)

      await this.$store
        .dispatch(REGISTRAR_ASIGNACION_SEGUIMIENTO_ESPECIALISTA_ACL, {
          datos: this.datosAsignacion
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.resetForm();
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerAsignaciones();
            this.obtenerDatosItem(
              this.nickUsuario,
              this.nombreUsuario,
              this.correoUsuario,
              2
            );
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroLoading = false;
          this.dialogLoaderVisible = false;
        })
        .catch((error) => {
          //console.log(error)
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro. REF::` + error
          );
          this.dialogLoaderVisible = false;
          this.btnRegistroLoading = false;
        });
    },

    //Obtener los datos especificos de una asignacion
    //Tipo 1 para montar el modal
    //Tipo de para solo ir a traer los proyecots
    async obtenerDatosItem(usuario, nombreUsuario, email, tipo) {
      this.nickUsuario = usuario;
      this.nombreUsuario = nombreUsuario;
      this.correoUsuario = email;
      this.datosAsignacion.usuario = usuario;

      if (tipo === 1) {
        this.dialogLoaderVisible = true;
        this.datosAsignacion = {};
      }

      await this.$store
        .dispatch(OBTENER_ASIGNACION_ESPECIALISTA_ACL, usuario)
        .then(res => {
          if (res.status === 200) {
            this.datosAsignacion = res.data;

            this.proyectosAsignadosUsuario = this.datosAsignacion.proyectos;
          } else {
            this.datosAsignacion.usuario = usuario;
          }
          if (tipo === 1) {
            this.dialogAsignacion = true;
          }

          this.dialogLoaderVisible = false;
        })
        .catch(() => {
          this.dialogLoaderVisible = false;
          this.datosAsignacion = {};
        });
    },

    mostrarProyectosUsuario(usuario, proyectos) {
      this.proyectosAsignadosUsuario = [];

      if (proyectos && proyectos.length > 0) {
        (this.nickUsuario = usuario),
          (this.proyectosAsignadosUsuario = proyectos);
        this.dialogProyectos = true;
      } else {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          `No exsiten proyectos asignados al usuario`
        );
      }
    },
  },

  created() {
    this.obtenerAsignaciones();
    this.obtenerEspecialistas();
    //this.obtenerEntidades();
    this.obtenerProyectosSicooperaEntidadUnidadEjecutora();
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inicio", route: "/" },
      { title: "Usuarios" },
      { title: "Asignación especialista" },
    ]);
  },

  computed: {
    likesAllRoles() {
      return this.proyectosSeleccionados.length === this.proyectos.length;
    },
    likesSomeRol() {
      return this.proyectosSeleccionados.length > 0 && !this.likesAllRoles;
    },
    icon() {
      if (this.likesAllRoles) return "mdi-close-box";
      if (this.likesSomeRol) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },

    headersAsignaciones() {
      return [
        /*   {
                    text: "Id",
                    align: "start",
                    sortable: true,
                    value: "id"
                }, */
        {
          text: "Usuario",
          align: "start",
          sortable: true,
          value: "usuario"
        },
        {
          text: "Nombre del Usuario",
          align: "start",
          sortable: true,
          value: "nombreUsuario"
        },
        {
          text: "Correo del Usuario",
          align: "start",
          sortable: true,
          value: "correoUsuario"
        },
        {
          text: "Proyecto(s) CD",
          align: "start",
          sortable: true,
          value: "proyectos"
        },
        /*  {
                    text: "Estado Asignación",
                    align: "start",
                    sortable: true,
                    value: "estado"
                }, */
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: ""
        }
      ];
    },

    headersProyectosSeleccionados() {
      return [
        {
          text: "Código",
          align: "start",
          sortable: true,
          value: "codigo"
        },
        {
          text: "Nombre del Proyecto",
          align: "start",
          sortable: true,
          value: "nombreProyecto"
        },
        /*{
          text: "Institución",
          align: "start",
          sortable: true,
          value: "nombreEntidad"
        },*/
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: ""
        }
      ];
    }
  }
};
</script>
<style lang=""></style>
