<template lang="">
    <div>
        <ul class="divisor">
            <li>
                <span class="color-chooser-color" :style="spanFirstChild"></span>
            </li>
            <li>
                <span class="color-chooser-color" style="background-color: #016099 !important; background-image:linear-gradient(90deg, #016099 0%, #62b9af;"></span>
            </li>
            <li>
                <span class="color-chooser-color" style="background-color: #62b9af !important; background-image:linear-gradient(90deg, #62b9af 0%, #6aa338;"></span>
            </li>
            <li>
                <span class="color-chooser-color" style="background-color: #6aa338 !important; background-image:linear-gradient(90deg, #6aa338 0%, #d02b2f;"></span>
            </li>
            <li>
                <span class="color-chooser-color" style="background-color: #d02b2f !important; background-image:linear-gradient(90deg, #d02b2f 0%, #facd01;"></span>
            </li>
            <li>
                <span class="color-chooser-color" style="background-color: #facd01 !important; background-image:linear-gradient(90deg, #facd01 0%, #c7602c;"></span>
            </li>
            <li>
                <span class="color-chooser-color" style="background-color: #c7602c !important; background-image:linear-gradient(90deg, #c7602c 0%, #d79a2a;"></span>
            </li>
            <li>
                <span class="color-chooser-color" style="background-color: #d79a2a !important; background-image:linear-gradient(90deg, #d79a2a 0%, #ac3964;"></span>
            </li>
            <li>
                <span class="color-chooser-color" style="background-color: #ac3964 !important; background-image:linear-gradient(90deg, #ac3964 0%, #dc84ba;"></span>
            </li>
            <li>
                <span class="color-chooser-color" :style="spanLastChild"></span>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: "DivisorColor",
    props: ['ptl', 'ptr', 'pbl', 'pbr'],

    computed: {
        spanFirstChild() {
            return {
                 "border-radius": `${this.ptl} 0 0 0`,
                 "background-color": "#268cc0 !important;",
                 "background-image":"linear-gradient(90deg, rgba(20,136,194,1) 0%, #016099"
                
            }
        },

        spanLastChild() {
            return {
                 "border-radius": `0 ${this.ptr} 0 0`,
                 "background-color": "#dc84ba !important;",
                 "background-image": "linear-gradient(90deg, #dc84ba 0%, #268cc0"
                
            }
        }
    }
    
}
</script>
<style scoped>

 .divisor {
  padding: 0;
  margin: 0;
  display: block;
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.divisor> li {
  display:inline-block;
  width: 10%;
}

/*.divisor> li:first-child > span{
      border-radius: v-bind('ptl') 0 v-bind('ptr') 0;
}

.divisor> li:last-child > span{
      border-radius: 0 var(--br-topright) 0 var(--br-bottomright);
} */


.divisor .color-chooser-color {
  display: block;
  width: 100%;
  height: 10px;
  text-align: center;
  
}
    
</style>